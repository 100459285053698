import React, { useState } from 'react';
import './Modal.css'; 
import AdditionalDetailsModal from './AdditionalDetailsModal';
import JD_Modal from './JD_Modal';

function AdditionalResultsModal({ additionalResults , onClose }) {
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isJD_ModalOpen, setIsJD_ModalOpen] = useState(false);

  // Get the first record's applicant name
  const firstRecord = additionalResults.length > 0 ? additionalResults[0] : null;
  const firstApplicantName = firstRecord ? firstRecord[3].applicant_name : 'N/A';

  // Measure the length of the array
  const arrayLength = additionalResults.length;

  // Sort the additionalResults by overall_scoring in descending order
  const sortedResults = [...additionalResults].sort((a, b) => {
    const scoreA = parseFloat(a[3]?.overall_scoring) || 0; // Default to 0 if score is NaN
    const scoreB = parseFloat(b[3]?.overall_scoring) || 0; // Default to 0 if score is NaN
    return scoreB - scoreA; // Sort in descending order
  });

  const getColor = (score) => {
    if (!score || score === 'N/A') return 'gray'; // Default color if score is not available

    const numericScore = parseFloat(score);

    if (numericScore >= 80) return 'green'; // High score
    if (numericScore >= 60) return 'orange'; // Medium score
    return 'red'; // Low score
  };
  
  const handleApplyClick = (index) => {
    const record = sortedResults[index];
    const score = parseFloat(record[3]?.overall_scoring);

    // Open the job application link if the score is 60 or above
    if (score >= 60) {
      window.open(record[5], '_blank', 'noopener,noreferrer');
    }
  };

  const handleOpenDetailsModal = (index) => {
    setSelectedRecord(sortedResults[index]);
    setIsDetailsModalOpen(true);
  };

  const handleOpenJD_Modal = (index) => {
    setSelectedRecord(sortedResults[index]);
    setIsJD_ModalOpen(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const closeJD_Modal = () => {
    setIsJD_ModalOpen(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-large">
        <span className="close" onClick={onClose}>&times;</span>
        <p>Hi, <strong>{firstApplicantName}</strong></p>
        <p>Based on your skills and experience, we found <strong>{arrayLength} jobs</strong> that may be a good fit for you</p>
        <ul className="results-list">
          {sortedResults.map((result, index) => {
            const [jobRef, jobTitle, jobDescription, analysis, jobDate] = result;
            return (
              <li key={index} className="results-item">
                <div className="result-header">
                  <strong className="job-title">{jobTitle}</strong>
                  <div className="result-buttons">
                    <button onClick={() => handleOpenJD_Modal(index)} className="JD-button">Job Desc.</button>
                    <button onClick={() => handleOpenDetailsModal(index)} className="Resume-analysis">Resume analysis</button>
                    <div className="tooltip-container">
                      <button 
                        className="apply-now-button" 
                        disabled={parseFloat(analysis?.overall_scoring) < 60}
                        onClick={() => handleApplyClick(index)}
                        style={{
                          backgroundColor: parseFloat(analysis?.overall_scoring) < 60 ? 'gray' : 'blue',
                          cursor: parseFloat(analysis?.overall_scoring) < 60 ? 'not-allowed' : 'pointer',
                        }}
                      >
                        Apply
                      </button>
                      {parseFloat(analysis?.overall_scoring) < 60 && (
                        <div className="tooltip">
                          Your match ratio prevents you from applying. Please revise your resume and resubmit
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="result-details">
                  <p className="match-ratio">
                    Your match ratio:
                    <span style={{ color: getColor(analysis?.overall_scoring) }}>
                      <strong> {analysis?.overall_scoring || 'N/A'}</strong>
                    </span>
                  </p>
                  <p className="job-ref">Job Ref.: {jobRef}</p>
                  <p className="additional-info">
                    Created date: {jobDate || 'N/A'}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>

        {isDetailsModalOpen && selectedRecord && (
          <AdditionalDetailsModal
            isOpen={isDetailsModalOpen}
            onClose={closeDetailsModal}
            record={selectedRecord}
          />
        )}
        
        {isJD_ModalOpen && selectedRecord && (
          <JD_Modal
            isOpen={isJD_ModalOpen}
            onClose={closeJD_Modal}
            record={selectedRecord}
          />
        )}
      </div>
    </div>
  );
}

export default AdditionalResultsModal;
