import React, { useState, useEffect } from 'react';
import './Modal.css';  // Optional: For styling the modal
import { ThreeDots } from 'react-loader-spinner';

function Modal({ isOpen, onClose, analysis0, analysis1 }) {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    let spinnerTimer;

    if (isOpen && analysis0 && analysis0.length > 0) {
      // Show spinner after 7 seconds
      spinnerTimer = setTimeout(() => {
        setShowSpinner(true);
      }, 7000);
    } else {
      // Reset spinner visibility when modal is closed or when arrayLength is 0
      setShowSpinner(false);
    }

    return () => {
      clearTimeout(spinnerTimer);
    };
  }, [isOpen, analysis0]);

  if (!isOpen) return null;

  // Measure the length of the array
  const arrayLength = Array.isArray(analysis0) ? analysis0.length : 'N/A';
  console.log('JD array length:', arrayLength);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h4 className="job-title" style={{ textAlign: 'justify' }}>
          Great News! Your Top 3 Job Titles retrieved are:
        </h4>
        <div>
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {Object.entries(analysis1).map(([key, value]) => (
              <li key={key} style={{ textAlign: 'left', paddingLeft: 0 }}>
                <strong className="match-ratio">{key}:</strong> {Array.isArray(value) ? value.join(', ') : value}
              </li>
            ))}
          </ul>
        </div>

        {/* Conditional Rendering based on arrayLength */}
        {arrayLength === 0 || arrayLength === 'N/A' ? (
          <>
            <p style={{ fontSize: '1em', textAlign: 'justify' }}>
              Unfortunately, there are no potential jobs available that match your skills at this time.
            </p>
            {/* Close button is shown only if arrayLength is 0 or N/A */}
            <div className="close" onClick={onClose}>&times;
             
            </div>
          </>
        ) : (
          <>
            <p style={{ fontSize: '1em', textAlign: 'justify' }}>
              Currently, there are {arrayLength} potential jobs for your set of skills.
            </p>
            {showSpinner && (
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
              }}>
                <br />
                <br />
                <p className="match-ratio">
                  Analyzing your resume against the job description - Hold on a little bit more while we do the work!
                </p>
                <ThreeDots
                  height="40" 
                  width="40" 
                  radius="9"
                  color="black" 
                  ariaLabel="loading..."
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  secondaryColor="gray"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;
