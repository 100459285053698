import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import './Modal.css';  // Using the provided CSS

function LoadingModal({ isOpen, imageUrl }) {
  if (!isOpen) return null;  // Only render the modal if isOpen is true	

  return (
    <div className="modal-overlay">
      <div className="modal-content">       
        <img src={imageUrl} alt="Loading..." style={{ width: '40%', height: 'auto' }} />
          <p>Processing your resume - Hang on tight!</p>
           <div className="spinner-container">
            <ThreeDots
                height="40" 
        	width="40" 
        	radius="9"
        	color="black" 
        	ariaLabel="loading..."
        	wrapperStyle={{}}
        	wrapperClass=""
        	visible={true}
        	secondaryColor="gray"
            />
           </div>
    	
       </div>
    </div>
  );
}

export default LoadingModal;
