import React, { useState, useEffect, useCallback } from 'react';
import Modal from './Modal';
import './Modal.css'; 
import LoadingModal from './LoadingModal';
import AdditionalResultsModal from './AdditionalResultsModal';

function FileUpload() {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [analysis0, setAnalysis0] = useState(null);
  const [analysis1, setAnalysis1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdditionalResultsModalOpen, setIsAdditionalResultsModalOpen] = useState(false);
  const [resumePath, setResumePath] = useState('');
  const [additionalResults, setAdditionalResults] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false); // New state to track if file is uploaded
  const [showUploadButton, setShowUploadButton] = useState(false); // Control button visibility


  const handleFileChange = (event) => {
    console.log('File selected from input:', event.target.files[0]); // Debug log
    setFile(event.target.files[0]);
  };

  const handleUpload = useCallback(() => {
    if (!file) {
      console.log('No file selected, skipping upload'); // Debug log
      setMessage('Please select a file to upload.');
      return;
    }

    console.log('Starting file upload...'); // Debug log
    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);

    const xhr = new XMLHttpRequest();
    
    xhr.onloadstart = () => {
  	console.log('Upload started');
    };


    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log('File uploaded successfully:', xhr.responseText); // Debug log
	setIsFileUploaded(true); // Set the file uploaded state to true
        setTimeout(() => {
          const response = JSON.parse(xhr.responseText);
          setMessage(response.message);
          setAnalysis0(response.analysis_0);
          setAnalysis1(response.analysis_1);
          setResumePath(response.resume_path);
          setIsModalOpen(true);
          setIsLoading(false);
        }, 1000);
      } else {
        console.log('Failed to upload file, status:', xhr.status); // Debug log
        setMessage('Failed to upload file');
        setIsFileUploaded(false); // Set the file uploaded state to false
     	setShowUploadButton(true); // Show button if upload fails
        setIsLoading(false);
      }
    };

    xhr.onerror = () => {
  	if (xhr.status === 0) {
    		console.log('Network error or request blocked');
    	if (!navigator.onLine) {
      			console.log('No internet connection');
    	} else {
      		console.log('CORS issue or browser security restriction might be blocking the request',xhr.status, xhr.statusText);
    		}
  	} else {
    		console.log('Error occurred during the file upload, status:', xhr.status, xhr.statusText,xhr.readyState);
  	}
  	setMessage('An error occurred during the file upload.');
        setIsFileUploaded(false); // Set the file uploaded state to false
        setShowUploadButton(true); // Show button if upload fails
  	setIsLoading(false);
    };

     
        	    
    // Ensure your backend endpoint is correct here
    console.log('Sending file to /api/upload...'); // Debug log
    xhr.open('POST', 'https://srv599626.hstgr.cloud/backend/api/upload');
    for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]); // Logs each form field and its value (including the file)
    }
    xhr.send(formData);
    }, [file]);

    // Handle the file input click
    const triggerFileInput = () => {
    	document.getElementById('file-upload').click();
    };  

  const closeModal = () => {
    console.log('Closing modal'); // Debug log
    setIsModalOpen(false);

    // Send a postMessage to Tampermonkey to close the iframe
    window.parent.postMessage({
        type: 'CLOSE_IFRAME'
    }, '*'); // '*' can be replaced with the exact origin if needed
  };

  const closeAdditionalResultsModal = () => {
    console.log('Closing additional results modal'); // Debug log
    setIsAdditionalResultsModalOpen(false);
    // Send a postMessage to Tampermonkey to close the iframe
    window.parent.postMessage({
        type: 'CLOSE_IFRAME'
    }, '*'); // '*' can be replaced with the exact origin if needed
  };

  const handleTampermonkeyUpload = useCallback((file) => {
    console.log('File received from Tampermonkey:', file); // Debug log
    setFile(file);
    handleUpload(); // Automatically trigger upload once the file is set
  }, [handleUpload]);

  // Automatically trigger handleUpload when a file is set from Tampermonkey
  useEffect(() => {
    if (file) {
      console.log('File set in state, triggering upload...'); // Debug log
      handleUpload();
    }
  }, [file, handleUpload]);

  useEffect(() => {
    if (isModalOpen && analysis0 && analysis0.length > 0) {
      const requestAdditionalAnalysis = () => {
        console.log('Requesting additional analysis...'); // Debug log
        fetch('https://srv599626.hstgr.cloud/backend/api/perform-additional-analysis', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ analysis0, resume_path: resumePath }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Received additional analysis:', data); // Debug log
            setMessage(data.message);
            setAdditionalResults(data.additional_results);
            setIsModalOpen(false);
            setIsAdditionalResultsModalOpen(true);
          })
          .catch(() => {
            console.log('Error occurred during additional analysis'); // Debug log
            setMessage('An error occurred during the additional analysis.');
          });
      };

      requestAdditionalAnalysis();
    }
  }, [isModalOpen, analysis0, resumePath]);

  // Listen for file received from Tampermonkey and check origin
  useEffect(() => {
    const receiveFileFromTampermonkey = (event) => {
      // Check if the origin matches (update 'https://your-tampermonkey-origin.com' to your actual origin)
      const validOrigin = ['https://ideandoinc.com', 'https://www.jobs.bestbuy.com/bby', 'https://www.jobs.bestbuy.com', 'https://srv599626.hstgr.cloud']; 
      if (!validOrigin.includes(event.origin)) {
        console.log('URL Origin', event.origin)
        console.log('Incoming message ignored due to origin mismatch');
        return;
      }
       console.log('Received file from a valid origin:', event.origin);

      // Check if the event type is correct and file is provided
      if (event.data.type === 'FROM_TAMPERMONKEY' && event.data.file) {
        console.log('Received file from Tampermonkey:', event.data.file);
        handleTampermonkeyUpload(event.data.file);
      } else {
        console.log('Invalid message received from Tampermonkey');
      }
    };

    window.addEventListener('message', receiveFileFromTampermonkey);

    return () => {
      window.removeEventListener('message', receiveFileFromTampermonkey);
    };
  }, [handleTampermonkeyUpload]);

  return (
    <div>  
      {/* <p>{message}</p> */}
      <input
        type="file"
        id="file-upload"
        onChange={handleFileChange}
        title="Select a file to upload"
        style={{ display: 'none' }} // Hide file input
      />   
      {showUploadButton && (
       <div>
        <p style={{ color: '#333', marginBottom: '10px' }}>
      	It seems that your resume was not uploaded. Please try again
    	</p>
        <button
          onClick={triggerFileInput}
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {isFileUploaded ? 'File Uploaded Successfully' : 'Upload Resume'}
        </button>
       </div>
      )}
      <LoadingModal isOpen={isLoading} imageUrl="/skillfit_logo.jpg" />
      <Modal isOpen={isModalOpen} onClose={closeModal} analysis0={analysis0} analysis1={analysis1} />
      {isAdditionalResultsModalOpen && additionalResults && (
        <AdditionalResultsModal additionalResults={additionalResults} onClose={closeAdditionalResultsModal} />
      )}
    </div>
  );
}
export default FileUpload;
