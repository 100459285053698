import React from 'react'; 
import './Modal.css'; 

const getColor = (score) => {
  if (!score || score === 'N/A') return 'gray'; // Default color if score is not available
  const numericScore = parseFloat(score);
  if (numericScore >= 80) return 'green'; // High score
  if (numericScore >= 60) return 'orange'; // Medium score
  return 'black'; // Low score
};

const getBackgroundColor = (score) => {
  if (!score || score === 'N/A') return 'gray'; // Default color if score is not available
  const numericScore = parseFloat(score);
  if (numericScore >= 80) return '#DEFCEE'; // High score
  if (numericScore >= 60) return '#FCF2DE'; // Medium score
  return '#FF7F7F'; // Low score
};

function AdditionalDetailsModal({ isOpen, onClose, record }) {
  if (!isOpen) return null;

  const score = parseFloat(record[3].overall_scoring);

  const handleApplyClick = () => {
    window.open(record[5], '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-large">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="header-container">
          <h4>Resume Analysis</h4>
          <span style={{ 
            color: getColor(record[3].overall_scoring),
            backgroundColor: getBackgroundColor(record[3].overall_scoring),
            borderStyle: 'none',
            borderRadius: '5px',
            padding: '4px 8px', 
          }}>
            <strong>Ratio: {record[3].overall_scoring || 'N/A'}</strong>
          </span>
        </div>
        <p style={{ 
          backgroundColor: '#f0f0f0',
          borderStyle: 'none',
          borderRadius: '5px',
          padding: '4px 8px',
          fontSize: '13px'
        }}>
          Your profile has {record[3].skills_experience_match.matched || 'N/A'} out
          of {record[3].skills_experience_match.total_required || 'N/A'} required skills and is missing connected experiences.
        </p>

        <div style={{ fontWeight: 'normal', fontSize: '13px' }}>
          <p>Here you find the analysis of your resume according to <strong>{record[1] || 'N/A'}</strong> position:</p>
          <p className="align-justify"><strong>Matches:</strong> {record[3].matches.length > 0 ? record[3].matches.join(', ') : 'None'}</p>
          <p style={{ textAlign: 'justify' }}><strong>Gaps:</strong> {record[3].gaps.length > 0 ? record[3].gaps.join(', ') : 'None'}</p>
        </div>

        <div className="details-results-button">
          <div className="tooltip-container">
            {score >= 60 ? (
              <button 
                className="apply-now-button" 
                onClick={handleApplyClick}
                style={{
                  backgroundColor: 'blue',
                  cursor: 'pointer',
                }}
              >
                Apply
              </button>
            ) : (
              <>
                <button 
                  className="apply-now-button" 
                  disabled
                  style={{
                    backgroundColor: 'gray',
                    cursor: 'not-allowed',
                  }}
                >
                  Apply
                </button>
                <div className="tooltip">
                  Your match ratio prevents you from applying. Please revise your resume and resubmit.
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdditionalDetailsModal;
