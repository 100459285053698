import React from 'react';
import './Modal.css'; 

function JobDescription({ description }) {
  const formattedDescription = description.split('\n').map((line, index) => {
    if (line.startsWith('*')) {
      return <li key={index}>{line.slice(1).trim()}</li>; // Handle list items
    } else {
      return <p key={index}>{line}</p>; // Handle paragraphs
    }
  });

  return <div>{formattedDescription}</div>;
}

const getColor = (score) => {
  if (!score || score === 'N/A') return 'gray';

  const numericScore = parseFloat(score);

  if (numericScore >= 80) return 'green'; 
  if (numericScore >= 60) return 'orange'; 
  return 'black'; 
};

const getBackgroundColor = (score) => {
  if (!score || score === 'N/A') return 'gray';

  const numericScore = parseFloat(score);

  if (numericScore >= 80) return '#DEFCEE'; 
  if (numericScore >= 60) return '#FCF2DE'; 
  return '#FF7F7F'; 
};

function JD_Modal({ isOpen, onClose, record }) {
  if (!isOpen) return null;

  const score = parseFloat(record[3].overall_scoring);

  // Function to handle the apply click
  const handleApplyClick = () => {
    if (score >= 60) {
      window.open(record[5], '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-large">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="header-container">
          <h4>Job Description</h4>
          <span style={{ 
            color: getColor(record[3].overall_scoring),
            backgroundColor: getBackgroundColor(record[3].overall_scoring),
            borderStyle: 'none',
            borderRadius: '5px',
            padding: '4px 8px',
          }}>
            <strong>Ratio: {record[3].overall_scoring || 'N/A'}</strong>
          </span>
        </div>

        <div style={{
          fontWeight: 'normal',
          fontSize: '13px'
        }}>
          <p>Here you find the job description for <strong>{record[1] || 'N/A'}</strong> position:</p>
          {/* Use the JobDescription component to display the formatted job description */}
          <JobDescription description={record[2] || 'N/A'} />
        </div>

        <div className="details-results-button">
          <div className="tooltip-container">
            <button 
              className="apply-now-button" 
              disabled={score < 60}
              onClick={handleApplyClick}
              style={{
                backgroundColor: score < 60 ? 'gray' : 'blue',
                cursor: score < 60 ? 'not-allowed' : 'pointer',
              }}
            >
              Apply
            </button>
            {score < 60 && (
              <div className="tooltip">
                Your match ratio prevents you from applying. Please revise your resume and resubmit.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JD_Modal;
